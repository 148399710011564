.timesheetViewTable tr {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 30px;
  text-align: center;
  height: auto;
  font-size: 16px;
  font-family: 'Nunito';
  margin-top: 2px;
  row-gap: 5px;
  align-items: center;
  border-bottom: 2px black;
  width: 900px;
  grid-gap: 10px;
}

.timesheetViewTable {
  background-color: white;
  border-radius: 2px;
  padding: 6px 32px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
  margin-top: 16px;
  margin-left: 15px;
}


.Link a {
  text-decoration: none;
  color: white;
}

.Link:hover a {
  text-decoration: none;
  color: #2F3940;
}

/* .timeSheetHeader input[type="date"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  border: 0px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
  border-radius: 2px;
  padding: 2px 8px;
  color: #3C4048;
  margin-top: 5px;
  height: 32px;
  font-size: 16px;

} */



/* Table 2 Admin */

.timesheetViewAdminTable tr {
  display: grid;
  grid-template-columns: 130px 100px 100px 120px 120px 120px 230px 70px;
  text-align: center;
  height: auto;
  font-size: 16px;
  font-family: 'Nunito';
  min-height: 50px;
  margin-top: 2px;
  row-gap: 5px;
  align-items: center;
  border-bottom: 2px black;
  width: auto;
  grid-gap: 10px;
}

.timesheetViewAdminTable {
  background-color: white;
  border-radius: 2px;
  margin-top: 16px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
  margin-top: 16px;
}

.timesheetViewAdminTable h6,p  {
  margin: 0px;
}

.timeSheetHeader {
  width: 170px;
  margin-left: 15px;
}
.timeSheetHeader label {
  text-align: left;
}

/* .timeSheetHeader {
  display: flex;
  align-items: center;

} */


/* .timeSheetHeader label {
  margin-right: 5px;
} */

.timeSheetAdminHeader {
  width: 70%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 30px;
  align-items: end;
}


/* .timeSheetAdminHeader select {
  border: 0px;
  border-radius: 2px;
  padding: 2px 8px;
  color: #3C4048;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
} */

/* .timeSheetAdminHeader input[type="date"] {
  border: 0px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
  border-radius: 2px;
  padding: 2px 8px;
  color: #3C4048;
} */

/* .timeSheetAdminHeader input {
  border: 0px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
  border-radius: 2px;
  padding: 2px 8px;
  color: #3C4048;
} */


/* ... existing CSS ... */

/* Styles for screens smaller than 600px */
@media only screen and (max-width: 600px) {

  .timeSheetWrapper {
    text-align: center;
    padding: 52px 0px;
  }

  .timesheetViewWrapper {
      overflow-x: scroll;
      width: auto;
      padding-left: 10px;
      min-height: 500px;
  }
}


.Admin tr {
  display: grid;
  grid-template-columns: 116px 74px 60px 86px 86px 270px 64px 84px 84px;
}
