.elementsGridWrapper {
  width: 100%;
}

.elementsGridHeaderWrapper {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  margin-top: 38px;
  height: 110px;
}


.elementsGridHeader1 {
  text-align: center;
  border: 1px solid #3C4048;
  border-right: 0px;
  align-items: center;
}

.elementsGridHeader2 {
  display: grid;
  grid-template-rows: 50% 50%;
  text-align: center;
  border: 1px solid #3C4048;
  border-right: 0px;
  align-items: center;
  width: 100%;
}

.elementsGridHeader3 {
  display: grid;
  grid-template-rows: 50% 50%;
  text-align: center;
  border: 1px solid #3C4048;
  border-right: 0px;
  align-items: center;
}

.elementsGridHeader4 {
  display: grid;
  grid-template-rows: 50% 50%;
  text-align: center;
  border: 1px solid #3C4048;
  border-right: 0px;
  align-items: center;
}

.elementsGridHeader5 {
  display: grid;
  grid-template-rows: 50% 50%;
  text-align: center;
  border: 1px solid #3C4048;
  align-items: center;
}

.elementsGridHeader1 {
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.elementsGridHeader2Row1 {
  font-weight: 600;
  border-bottom: 1px solid #3C4048;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.elementsGridHeader3Row1 {
  font-weight: 600;
  border-bottom: 1px solid #3C4048;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.elementsGridHeader4Row1 {
  font-weight: 600;
  border-bottom: 1px solid #3C4048;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

}
.elementsGridHeader5Row1 {
  font-weight: 600;
  border-bottom: 1px solid #3C4048;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

}

.elementsGridHeader2Row2 {
  display: grid;
  grid-template-columns: 33% 33% 34%;
  font-size: 12px;
  height: 100%;
}

.elementsGridHeader3Row2 {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  font-size: 12px;
  height: 100%;
}

.elementsGridHeader4Row2 {
  display: grid;
  grid-template-columns: 50% 50%;
  font-size: 12px;
  text-align: center;
  height: 100%;

}

.elementsGridHeader5Row2 {
  display: grid;
  grid-template-columns: 73% 27%;
  font-size: 12px;
  text-align: center;
  height: 100%;

}

.elementsGridHeader2Row2Col1 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #3C4048;
}

.elementsGridHeader2Row2Col2 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #3C4048;
}

.elementsGridHeader2Row2Col3 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 2px;
}


.elementsGridHeader3Row2Col1 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #3C4048;
}

.elementsGridHeader3Row2Col2 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #3C4048;
}

.elementsGridHeader3Row2Col3 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 2px;
}


.elementsGridHeader4Row2Col1 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #3C4048;
}

.elementsGridHeader4Row2Col2 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.elementsGridHeader5Row2Col1 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #3C4048;
}

.elementsGridHeader5Row2Col2 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* CONTENT */

.elementsGridContentWrapper {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
}

.elementsGridContent1 {
  text-align: center;
  border: 1px solid #3C4048;
  border-right: 0px;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 20px;
  border-top: 0px;
}

.elementsGridContent2 {
  display: grid;
  grid-template-columns: 33% 33% 34%;
  border-right: 1px solid #3C4048;
  width: 100%;
}

.elementsGridContent2Col1 {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #3C4048;
  border-top: 0px;
  border-right: 0px;

}

.elementsGridContent2Col2 {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #3C4048;
  border-top: 0px;
  border-right: 0px;

}

.elementsGridContent2Col3 {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #3C4048;
  border-top: 0px;
  border-right: 0px;
}

.elementsGridHeader3Line {
  display: grid;
  grid-template-columns: 33% 33% 34%;
  font-size: 13px;
  text-align: center;
  border-bottom: 1px solid #3C4048;
}

.elementsGridContent3 {
  border-bottom: 1px solid #3C4048;
}

.elementsGridContent4 {
  border-bottom: 1px solid #3C4048;
  border-left: 1px solid #3C4048;
}

.elementsGridContent3LineCell {
  height: 100%;
}

.elementsGridContent3LineTotal {
  border-right: 0px;
  border-left: 0px;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  padding: 2px 2px;
}

.elementsGridContent5 {
  display: grid;
  grid-template-columns: 73% 27%;
  border: 1px solid #3C4048;
  border-top: 0px;
}

.elementsGridContent5Cell1 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  border-right: 1px solid #3C4048;
}

.elementsGridContent5Cell2 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}


.elementsGridWrapper input {
  width: 100%;
  border: 0px;
  text-align: center;
  color: #3C4048;
  font-size: 13px;
  background-color: #F7FAFC;
  height: 100%;
  width: 90%;
  border-radius: 0px;

}


.elementsGridWrapper textarea {
  color: #3C4048;
  width: 100%;
  border: 0px;
  text-align: left;
  font-size: 13px;
  background-color: #F7FAFC;
}

.elementsVariables select {
  border: 0px;
  border-radius: 2px;
  padding: 2px 8px;
  color: #3C4048;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
  margin-right: 15px;
}

.btnUpdate {
  margin-top: 16px;
}

.elementsBtns {
  width: 30%;
  display: flex;
  align-items: center;
}

.Red {
  background-color: #EF6262;
  border: 2px solid #EF6262;
}

.Red:hover {
  color: #EF6262;
  border: 2px solid #EF6262;
  background-color: white;
}

.elementsGridHeader1 p,
.elementsGridHeader2Row1 p,
.elementsGridHeader2Row2 p,
.elementsGridHeader3 p,
.elementsGridHeader4 p,
.elementsGridHeader5 p
{
  font-weight: 500;
  font-size: 14px;
}

.elementsGridContentWrapper p,
.elementsGridContentWrapper input {
  font-size: 14px;
  font-family: 'Nunito Sans', sans-serif;
}
