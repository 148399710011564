.timeSheetWrapper {
  padding: 52px 52px;
  color:#3C4048;
  width: auto;
  min-height: 300px;
}


.timeSheetWrapper h3 {
  text-align: center;
}

.timeSheetWrapper {
  margin: 0px 0px 5px 0px;
}

.timeSheetDate {
  margin-bottom: 16px;
  width: 300px;
}

.timeSheetForm h6 {
  margin: 0px 0px 5px 0px;
}

.timeSheetForm {
  display: grid;
  grid-gap: 16px;
  margin: 0px 10% 0px 0px;
  width: 60vw;
}

.timeSheetDay h5 {
  font-size: 18px;
  font-weight: 400;
  margin: 0px 0px 16px 0px;
  text-align: center;
}

.timeSheetDay {
background-color: white;
box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
margin-top: 16px;
border-radius: 2px;
padding: 25px 32px;
}


.timeSheetTableContainer {
  display: grid;
  grid-gap: 10px; /* Set the gap between cells */
}

.timeSheetCustomTable {
  margin: auto;
  background-color: white;
  text-align: center;
  border-radius: 2px;
  margin-bottom: 16px;
}

.addBox td {
  text-align: left;
}

.timeSheetCustomTable tr {
  display: grid;
  grid-template-columns: 116px 74px 74px 86px 86px 270px 20px 64px;
  grid-gap: 12px;
  margin-bottom: 4px
}

.Read tr {
  display: grid;
  grid-template-columns: 116px 74px 86px 86px 270px 84px;
}

.Read input[type="text"] {
  color: #3C4048;
}

.Read input[type="number"] {
  color: #3C4048;
}


.timeSheetCustomTable th,
.timeSheetCustomTable td {
  padding: 0px; /* Set the cell padding */
}

.timeSheetCustomTable input {
  border: 0px;
  border-radius: 2px;
  height: 30px;
  padding: 2px 8px;
  color: #3C4048;
}

.timeSheetCustomTable select {
  border: 0px;
  border-radius: 2px;
  height: 34px;
  padding: 2px 8px;
  color: #3C4048;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;

}

.timeSheetCustomTable input::placeholder {
  color: #B7B7B7;
  font-size: 14px;
  font-weight: 300;
}

.bl input {
  width: 100px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
}

.comm input{
  border-radius: 2px;
  width: 90%;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
}

.hr input {
  width: 50px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
}


.night td {
  border: 0px white;
  border-radius: 2px;
  padding: 3px;
}

.night input[type="checkbox"] {
  -webkit-appearance: checkbox;
}

.timeSheetTotal {
  padding: 10px 20px;
  background-color: white;
  width: 250px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
  border-radius: 2px;
  margin-bottom: 10px;
  margin-top: 15px;
}

.timeSheetTotal button {
  margin-top: 10px;
}

.totalDay {
  text-align: center;
}

.flexLabel input[type="checkbox"] {
  -webkit-appearance: checkbox;
}

.timeSheetDate, .flexLabel {
  margin-left: 10px;
}


.timeSheetDayOptions {
  margin-left: 10px;
  margin-bottom: 16px;
}

@media only screen and (max-width: 900px) {
  .login {
    justify-content: space-between;
  }

  .timeSheetForm {
    overflow-x: scroll;
    width: 90vw;
  }

  .timeSheetWrapper {
    padding: 52px 52px;
  }

  .timeSheetDay {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .login {
    justify-content: space-between;
  }

  .timeSheetTotal {
    margin-left: 10px;
  }

  .timeSheetForm {
    overflow-x: scroll;
    width: 90vw;
  }

  .timeSheetWrapper {
    padding: 52px 52px;
  }

  .timeSheetDay {
    margin-left: 10px;
    margin-right: 10px;
  }

  .timeSheetDay h5 {
    text-align: left;
    margin-left: 20px;
  }

  .timeSheetDate {
    width: 100%;
  }
}
