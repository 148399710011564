.siteReportSheetDate p {
  margin: 0px;
}

.siteReportVehicule {
  text-align: left;
  display: grid;
  grid-template-columns: 81px 50px;
  margin-top: 20px;
  align-items: center;
  align-content: center;
}

.siteReportVehicule p {
  align-items: center;
  margin: 0px;
}
.siteReportVehicule input {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
  border-radius: 2px;
  border: 0px;
  color:#3C4048;
  font-size: 14px;
  height: 25px;
  padding-left: 6px
}

.siteReportVehiculeItem {
  align-self: center;
}

.siteReportWrapper {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
  margin-top: 16px;
  border-radius: 2px;
  padding: 25px 32px;
  width: 600px;
}

.siteReportGrid {
  display: grid;
  grid-template-rows: 20px;
  row-gap: 10px;
}

.siteReportTravelTime {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 170px 75px;
  text-align: left;
}

.siteReportTravelTime p {
  margin: 0px;
  text-align: left;
}

.siteReportTravelTime input {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
  border-radius: 2px;
  border: 0px;
  color:#3C4048;
  font-size: 14px;
}

.siteReportConstructionSite {
  display: grid;
  grid-template-columns: 170px 250px;
  text-align: left;
}


.siteReportConstructionSite p {
  margin: 0px;
  text-align: left;
}

.siteReportConstructionSite input {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
  border-radius: 2px;
  border: 0px;
  height: 28px;
  color:#3C4048;
  font-size: 14px;
  padding-left: 10px;
}



.siteReportComment {
  display: grid;
  grid-template-columns: 170px 250px;
  text-align: left;
}

.siteReportComment input {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
  border-radius: 2px;
  border: 0px;
  height: 70px;
  color:#3C4048;
  font-size: 14px;
  padding-left: 10px;
}

.siteReportComment p {
  margin: 0px;
  text-align: left;
}

.siteReportHoursWorked {
  display: grid;
  grid-template-columns: 170px 250px;
  text-align: left;
}

.siteReportHoursWorked input {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
  border-radius: 2px;
  border: 0px;
  color:#3C4048;
  font-size: 14px;
  padding-left: 10px;
  width: 50px;
}

.siteReportHoursWorked p {
  margin: 0px;
  text-align: left;
}

.siteReportCountMeal {
  display: grid;
  grid-template-columns: 170px 250px;
  text-align: left;
}

.siteReportRestaurant {
  display: grid;
  grid-template-columns: 170px 250px;
  text-align: left;
}

.siteReportRestaurant p {
  margin: 0px;
  text-align: left;
}

.siteReportRestaurant input {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
  border-radius: 2px;
  border: 0px;
  color:#3C4048;
  font-size: 14px;
  padding-left: 10px;
  width: 200px;
  height: 30px
}

.siteReportEmployees {
  display: grid;
  grid-template-columns: 170px 250px;
}

.siteReportEmployees p {
  margin: 0px;
  text-align: left;
}



/* .siteReportMultiSelect {
  display: grid;
  grid-template-columns: 170px 300px;
} */

.siteReportMultiSelect input {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
  border-radius: 2px;
  border: 0px;
  color:#3C4048;
  font-size: 14px;
  padding-left: 10px;
  width: 200px;
  height: 30px;
}


.searchName button {
  background-color: #FF6969;
  margin-top: 8px;
  height: 28px;
  border: 0px;
  border-radius: 2px;
  margin-right: 5px;
  margin-left: 5px;

}

.listName button {
  margin-top: 8px;
  background-color: #96C291;
  border: 0px;
  height: 28px;
  border-radius: 2px;
  color:#3C4048;
  margin-right: 5px;
  margin-left: 5px;

}

.siteReportView h3 {
  text-align: center;
  width: 70vw;
}

.siteReportSave {
  margin-top: 30px;
}

.siteReportFromDate p {
  text-align: center;
}


.notSelectedDay {
  margin-bottom: 16px;
  cursor: pointer;
  margin-right: 10px;
  background-color: #2F3940;
  border-radius: 2px;
  border: 0px;
  padding: 8px 12px;
  color: white;
  border: 2px solid #2F3940;
  font-size: 14px;
  font-family: 'Nunito Sans', sans-serif;
}

.selectedDay  {
  cursor: pointer;
  margin-bottom: 16px;
  margin-right: 10px;
  background-color: #2F3940;
  padding: 8px 12px;
  border-radius: 2px;
  border: 0px;
  color: white;
  font-size: 14px;
  cursor: pointer;
  border: 2px solid #EAF205;

}


@media only screen and (max-width: 900px) {

  .siteReportView {
    overflow-x: scroll;
    width: 100vw;
    min-height: 100vh;
  }

  .siteReportView h3 {
    text-align: center;
    margin: auto;
    margin-bottom: 30px;
  }

  .siteReportSheetDate {
    margin-left: 20px;
  }

  .siteReportVehicule {
    margin-left: 20px;
  }

  .siteReportWrapper {
    margin-left: 20px;
    margin-right: 20px;
  }

  .siteReportSave {
    text-align: left;
    margin-left: 20px;
    margin-bottom: 30px;
  }
  .siteReportMultiSelect input {
    margin-left: -37px;
  }
}
