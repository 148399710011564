.App {
  height: 100%;
}

.navigationWrapper {
  display: grid;
  grid-template-columns: 250px 1fr;
  background-color: white;
}

.full-width {
  display: block;
}

body, html {
  height: 100%;
  margin: 0;
  padding: 0;
}


.navigationContainer {
  display: flex;
  min-height: 100vh;
  width: 100%;
  background-color: white;
  border-right: 1px solid #EDEDED;

}

.companyInfos img {
  height: 60px;
  border-radius: 100%;
  margin-top: 10px;
}




.companyInfos {
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  margin-left: 44px;
}


.createPost {
  padding: 0px 0px 12px 18px
}

.createPost a {
  text-decoration: none;
  color: white;
}


.navigationTitle {
  font-size: 16px;
  font-weight: 600;
  margin: 16px 0px 0px 34px;
}

.navigationTitleModal {
  font-size: 16px;
  font-weight: 600;
  margin: 16px 0px 0px 10px;
}

.navigationMenu {
  padding: 4px 0px 4px 21px;
  margin: 0px 0px 0px 5px;
  background-color: white;
  width: 240px;
  text-align: left;
  border: 0px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  color: #3C4048;

}

.navigationMenu:hover {
  background-color: #FEF9F4;
}

.error-message span {
  font-size: 12px;
}

.navigationMenu a {
  text-decoration: none;
  color: #3C4048;
  width: 300px;
  display: block;
}

.flexLabel label {
  display: flex;
}
