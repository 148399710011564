.addUserGridWrapper {
  display: grid;
  grid-template-columns: 304px 110px 38px;
  grid-column-gap: 0px;
  align-items: center;
}

.addUserGridWrapper input {
  margin-bottom: 10px;
  height: 30px;
  font-size: 14px;
}

.addUserGridWrapper select {
  margin-bottom: 10px;
  height: 30px;
  font-size: 12px;
}

.addUserGridWrapper button {
  margin-bottom: 10px;
  height: 30px;
  margin-top: 0px;
}

.addUserGridWrapper select {
  border: 0px;
  border-radius: 2px;
  padding: 2px 8px;
  color: #3C4048;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
  margin-right: 15px;
}

.addUserGridWrapper input {
  border: 0px;
  border-radius: 2px;
  padding: 2px 8px;
  color: #3C4048;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
  margin-right: 15px;
  align-items: center;
}

.delete {
  background-color: #EF6262;
}

.addUserButtonGridWrapper {
  display: grid;
  grid-template-columns: 30px 130px;
  margin-top: 10px;
  height: 30px;

}

.addUserButtonGridWrapper button {
  margin-top: 0px;
}

.buttonPlus {
  font-size: 7px;
  background-color: #2F3940;
  border-radius: 2px;
  border: 2px solid #2F3940;
  color: white;
}

.buttonPlus:hover {
  font-size: 7px;
  background-color: white;
  border-radius: 2px;
  border: 2px solid #2F3940;
  color: #2F3940;
}

.addUserGridWrapper p {
  text-align: left;
}
.supportView h4 {
  font-weight: 500;
  margin-top: 60px
}
