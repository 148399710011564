.dd-header {
  cursor: pointer;
  width: 260px;
  height: 36px;
  background: white;
  border: 0px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
  border-radius: 5px;
  margin-bottom: 16px;
}

.dd {
  display: flex;
  width: 320px;
}

.dd-header-open {
  width: 260px;
  height: 36px;
  background: white;
  border: 0px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
  cursor: pointer;
}

.dd-header-title {
  /* font-weight: lighter; */
  color: grey;
}

.dd-header-title p {
  font-size: 16px;
  font-weight: 300;
}

.dd-wrapper .icon {
  font-size: 10px;
  color: grey;
}

.dd-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 28px 0 10px;
  height: 36px;
}

.dd-list {
  position: absolute;
  display: grid;
  width: 260px;;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
  z-index: 1000;
  color: #2F3940;
  margin-top: 36px;
  -webkit-tap-highlight-color: transparent;
}

.dd-list-item {
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0px 28px 0px 10px;
  background: white;
  border: 0px;
  cursor: pointer;
  font-size: 16px;
  color: #2F3940;
}


.icon{
  font-size: 6px;
  color: grey;
}

.dd-wrapper {
  position: relative;
}


.dd-required {
  color: red;
  margin: 2px 0px 0px 10px;
  font-size: 11px;
}

.selected {
  color: #2F3940;
}


.inputText {
  max-width: 260px;;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
  border: 0px;
  border-radius: 5px;
  padding: 0 28px 0 10px;
  cursor: pointer;
  height: 36px;
  font-weight: lighter;
}

.inputText::placeholder {
  color: grey;
}



@media only screen and (max-width: 600px) {
  .dd {
    display: flex;
    width: 320px;
    margin: auto;
  }
}
