.loginWrapper {
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  text-align: center;
  font-family: 'Nunito';
}

.login img {
  height: 60px;
  border-radius: 100%;
  text-align: left;
}

.btn {
  margin: auto;
}

.login {
  position: relative;
  background-color: white;
  align-items: center;
  display: flex;
  height: 80px;
  margin-left: 8px;
  justify-content: end;
}

.login button {
  height: min-content;
}

.modalButton {
  color: #2F3940;
}

.btnModal {
  height: min-content;
  background-color: white;
  border: 0px;
  text-decoration: underline;
  color: #0154AB;
  padding: 8px 12px;
  border: 0px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 10px;
}

.modal {
  z-index: 1;
}
.loginWrapper h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 18px;
  margin-top: 10px;
  color: #3C4048;
}

.loginWrapper p {
  margin-bottom: 16px;
}

.loginWrapper img {
  width: 100px;
  border-radius: 100%;
}


/* Styles for screens smaller than 600px */
@media only screen and (max-width: 900px) {
  .login {
    justify-content: space-between;
  }
}
