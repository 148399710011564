.question-checkboxe {
  display: grid;
  grid-template-rows: 25% 25% 25% 25%;
}

.checkboxHeight {
  width: 20px;
}

.checkbox-wrapper {
  width: 20px;
  display: grid;
  grid-template-columns: 20px auto;
  grid-column-gap: 10px;
  align-items: center;
  font-size: 16px;
  font-weight: lighter;
  position: relative;
}

.custom-checkbox {
  position: relative;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
  cursor: pointer;
}

.checkbox-wrapper label {
  font-size: 16px;
  font-weight: lighter;
}

.checkbox-wrapper input[type="checkbox"] {
  appearance: none;
  cursor: pointer;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  display: none;
  font-size: 30px;
  color: white;
  left: -1px;
  top: -2px;
  cursor: pointer;

}

.visible {
  display: block;
}

.custom-checkbox.checked {
  background-color: #4D77FF;
  border-color: #4D77FF;
}
