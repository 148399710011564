input[type="text"],
input[type="radio"],
input[type="number"],
input[type="tel"] {
    -webkit-text-size-adjust: 100%;
}


/* .onboardingForm p {
  margin-bottom: 5px;
  margin-top: 7px;
} */

.onboardingForm p {
  text-align: center;
}


.onboardingForm form {
  display: grid;
  grid-template-rows: 1fr;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: auto;
  margin: auto auto; /* Center your form */
}

.onboardingForm label {
  display: flex;
  display: inline;
  gap: 10px;
  align-items: center;
}

.onboardingForm input[type="radio"] {
  -webkit-appearance: checkbox;
}


.onboardingForm h6 {
  text-align: center;
}

.onboardingForm label input {
  color: #3C4048;
}

.onboardingForm {
  margin-top: 52px;
}


.phone-input {
  width: 200px;
  border: none;
  background-color: transparent;
  transition: all 0.3s ease; /* Optional: add transition for smooth effect on focus */
}


/* Style the country select dropdown */
.phone-input .PhoneInputCountrySelect .PhoneInputCountrySelectFocusTrap--after,
.phone-input .PhoneInputCountrySelect .PhoneInputCountrySelectFocusTrap--before {
  border: none;
}

.phone-input .PhoneInputCountrySelectDropdown {
  border-radius: 5px;
}

.phone-input .PhoneInputCountryIcon {
  border-radius: 2px;
}
