/* Colors:

Grey: #AGA6A6
Black: #2F3940
Jaune 1: #808C20
Jaune 2: #D2D91A
Jaune 3: #EAF205 */

/* Green: #66DE93
Red: #FF616D */
/* TEXT */

h1, h2, h3, h4, h5, h6, p, button, label, select, input, textarea {
  font-family: 'Nunito Sans', sans-serif;
}

body {
  margin: 0px;
  background-color: white;
}

.App {
  width: 100vw;
  box-sizing: border-box;
  overflow-x: hidden;
  background-color: white;
}


h2 {
  font-size: 36px;
  font-weight: 600;
  color: #3C4048;
  margin-top: 0px;
  margin-bottom: 16px;
  line-height: 54px;
}

h3 {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 5px;
  color: #3C4048;
}

h6 {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 16px;
  margin-top: 0px;
}

h2, h3, h6, p {
  color: #2F3940;
}

.timeSheetForm h6 {
  font-size: 16px;
  font-weight: 400;
  color: #2F3940;
}


.timesheetViewTable h6  {
  font-size: 16px;
  font-weight: 400;
  color: #2F3940;
  margin: 0px;
}

.timesheetViewAdminTable h6  {
  font-size: 16px;
  font-weight: 400;
  color: #2F3940;
  margin: 0px;
}

p {
  font-size: 16px;
  font-weight: 300;
  color: #3C4048;
  margin-bottom: 16px;
  margin-top: 0px;
  line-height: 24px;
}

/* INPUT */

input, select {
  font-size: 16px;
  font-weight: 300;
  height: 36px;
  padding: 0px 10px;
  border: 0px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(45, 58, 75, 0.16) 0px 0px 0px 1px, rgba(45, 58, 75, 0.08) 0px 2px 5px 0px;
  outline: none;
  color: #2F3940;
}

input[type="checkbox"] {
  -webkit-appearance: checkbox;
  height: 18px;
  border-radius: 3px;
  border: 1px solid #2F3940;
  margin: 0px;
  box-sizing: content-box;
  box-shadow: none;
  cursor: pointer;
}

input, textarea, select, button {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="checkbox"] {
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
}


.labelInput {
  display: grid;
}

.labelInputInline {
  display: grid;
  grid-template-columns: min-content min-content;
  grid-gap: 5px;
  align-items: center;
  margin-bottom: 16px;
  margin-left: 10px;
}

.labelInputInline label {
  margin-bottom: 0px;
}

.labelInputInlineFlex {
  display: grid;
  grid-template-columns: auto min-content;
  grid-gap: 5px;
  align-items: center;
  margin-bottom: 16px;
  margin-left: 10px;
  width: fit-content;
}

.labelInputInlineFlex label {
  margin-bottom: 0px;
}

label {
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  font-size: 16px;
  margin-bottom: 8px;
  color: #3C4048;
}

/* BUTTON */

.btn {
  background-color: #2F3940;
  padding: 8px 12px;
  border-radius: 2px;
  border: 0px;
  color: white;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  border: 2px solid #2F3940;
  font-size: 14px;
}

.btn:hover {
  color: #2F3940;
  border: 2px solid #2F3940;
  color: #2F3940;
  background-color: white;
}

.btn p {
  margin: 0px;
}

.btnTimeSheeDelete {
  margin-top: 5px;
  color: #FF616D;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
  padding: 4px 5px;
  border-radius: 2px;
  border: 0px;
  background-color: white;
  font-size: 8px;
  font-weight: 500;
  cursor: pointer;
}

.btnTimeSheet {
  margin-top: 5px;
  color: white;
  padding: 5px 7px;
  border-radius: 2px;
  border: 0px;
  background-color: #2F3940;
  font-size: 7px;
  font-weight: 500;
  cursor: pointer;
}


.btnTimeSheet:hover {
  margin-top: 5px;
  color: #EAF205;
  padding: 5px 7px;
  border-radius: 2px;
  border: 0px;
  background-color: #2F3940;
  font-size: 7px;
  font-weight: 500;
  cursor: pointer;
}

.modalButton {
  border: 0px;
  background: white;
  padding: 10px 10px;
  cursor: pointer;
}

.modalButton svg {
  border: 0px;
  background: white;
  padding: 10px 10px;
  height: 18px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
  border-radius: 2px;
}

.contentWrapper {
  height: 100%;
}

.featureTitle {

  border-bottom: 1px solid #EDEDED;
  margin-bottom: 32px;
}
