.supportView h5 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
}

.supportView p {
  margin: 0px;
  margin-bottom: 10px;
}


.supportContact {
  display: grid;
  grid-template-columns: 19% 16%;
  grid-gap: 5px;
}

.supportContact  a {
  text-decoration: none;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: 400;
  display: grid;
  grid-gap: 5px;
}


.supportView h3 {
  margin-bottom: 5px;
  text-align: center;
}

.supportView p {
  margin: 0px;
  margin-bottom: 10px;
  text-align: center;
}

.supportContact {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5px;
  text-align: center;
}

.supportContact  a {
  text-decoration: none;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: 400;
  display: grid;
  grid-gap: 5px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
  background-color: white;
  color: #3C4048;
}

/* Small devices (landscape phones, less than 600px) */
@media (max-width: 600px) {
  .supportContact {
    grid-template-columns: 1fr; /* One column on small screens */
  }
}
