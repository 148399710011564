.heatmap {
  display: grid;
  grid-template-columns: [name-start] 150 [name-end] repeat(52, [week-start] 1fr [week-end]);
  grid-gap: 2px;
}


.header {

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  font-weight: bold;
}

.name {
  grid-column: 1;

}

/* This will hide the fromDate by default */
.fromDateTooltip {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: 100%;  /* or 'top: 0;' depending on where you want the tooltip */
  left: 50%;
  width: 140px;
  transform: translateX(-50%);
  background-color: #333;  /* Change as needed */
  padding: 5px;
  border-radius: 3px;
  z-index: 2;
  transition: opacity 0.3s, visibility 0.3s;
  text-align: center;
}

.fromDateTooltip p {
  color: white !important;
  font-size: 13px;
}

/* This will display the fromDate when the week card is hovered */
.week:hover .fromDateTooltip {
  visibility: visible;
  opacity: 1;
}



.week {
  position: relative;
  /* ... your other styles ... */
}


.week > a {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: block;
}


/* Weekly statuses */
.week {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  border-radius: 2px;

}

.week.notValidated {
  background-color: #ff9999; /* Red for not submitted */
}

.week.validated {
  background-color: #99ff99; /* Blue for validated */
}

.week.notSubmitted {
  background-color: lightgray; /* Yellow for rejected */
}

/* You can add more colors for more statuses, if needed */
.selectYearBtn button {
  margin-left: 10px;
}

.selectYearBtn p {
  margin-top: 10px;
}

.selectYearBtn {
  text-align: center;
  margin-bottom: 20px;
}
