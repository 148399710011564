
.modal {
  background-color: blue;
  border-radius: 2px;
  margin-right: 10px;
  width: 240px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
  padding: 10px;
  position: absolute;
  right: 10px;
  z-index: 100;
}

.modal img {
  border-radius: 100%;
  height: 40px;
  margin-right: 5px;
}

.userInfos {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
