.dashboardCeoWrapper {
  font-family: 'Nunito Sans', sans-serif;
  margin-right: 100px;
}

.dashboardCeoWrapper {
  display: grid;
  grid-template-rows: min-content min-content auto;
  grid-gap: 20px;
  width: 70%;
}

.dashboardCeoFilters select {
  border: 0px;
  border-radius: 2px;
  padding: 2px 8px;
  color: #3C4048;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px;
}


.dashboardCeoFilters {
  padding-left: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 130px 130px 1fr 1fr;
  grid-template-rows: 15% 15%;
  grid-gap: 30px;
  align-items: end;
  padding-top: 32px;
}



.dashboardCeoLeft {
  display: grid;
  grid-template-rows: 30% 70%;
}


/* Table */


/* Table styles */
.dashboardCeoTable table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-family: Arial, sans-serif;
  border: 1px solid #e0e0e0;
}

.dashboardCeoTable tr {
  font-size: 14px;
}

.dashboardCeoTable tr,
.dashboardCeoTable th,
.dashboardCeoTable td {
  padding: 8px 12px;
  border: 1px solid #e0e0e0;
  text-align: left;
}

.dashboardCeoTablet head {
  background-color: #f7f7f7;
  font-weight: bold;
}

.dashboardCeoTablet tbody tr:hover {
  background-color: #f0f0f0;
}

.dashboardCeoTablet tbody tr:nth-child(even) {
  background-color: #fafafa;
}
